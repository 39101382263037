
import { defineComponent } from "vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { TableSelectionConfig } from "@/components/controls/catalogueGrid/tableSelectionConfig";
import { TableDesignConfig } from "@/components/controls/catalogueGrid/tableDesignConfig";
import { TextColumnConfig } from "@/components/controls/catalogueGrid/columns/textColumnConfig";
import { IntegerColumnConfig } from "@/components/controls/catalogueGrid/columns/integerColumnConfig";
import CatalogueGrid from "@/components/controls/catalogueGrid/CatalogueGrid.vue";
import { DecimalColumnConfig } from "@/components/controls/catalogueGrid/columns/decimalColumnConfig";
import { EnumColumnConfig } from "@/components/controls/catalogueGrid/columns/enumColumnConfig";
import PlanTransportGroupResultDetailJournal from "./PlanTransportGroupResultDetailJournal.vue";
import { ability } from "@/abilities/ability";
import store from "@/store";

export default defineComponent({
  components: {
    CatalogueGrid,
    PlanTransportGroupResultDetailJournal,
  },
  props: {
    //показатель режима выбора
    selectMode: { required: false, type: Boolean, default: false },
    gridHeight: { required: false, type: String, default: null },
    //функция для получения выбранных элементов
    getSelectedData: { type: Function },
    //функция для вызова при изменении выбранных элементов
    changeSelectedData: { type: Function },
    //модель родительского элемента
    parentModel: { required: true },
    //идет расчет плана
    isCalculation: { required: false },
  },
  computed: {},
  methods: {
    onRowPrepared(e: any) {
      if (e.rowType == "header") {
        e.rowElement.bgColor = "#E5F0FF";
      }
    },
    setColums() {
      if (ability.can("view", "Modules.Order")) {
        this.columns.push(
          new TextColumnConfig({
            dataField: "orderDescription",
            caption: "Заказ",
          })
        );
      }
    },
  },
  data() {
    return {
      // подробности работы custom summary см. на сервере в методе SetCustomSummary
      summaryConfig: {
        totalItems: [
          {
            name: "TotalSummary",
            showInColumn: "name",
            displayFormat: "Кол-во (шт)",
            summaryType: "custom",
          },
          {
            name: "TotalSummary2",
            showInColumn: "name",
            displayFormat: "Грузоподъемность (кг)",
            summaryType: "custom",
          },

          {
            name: "PlanCountSummary",
            showInColumn: "planCount",
            column: "planCount",
            valueFormat: "#,##0.#",
            displayFormat: "{0} шт",
            summaryType: "sum",
          },
          {
            name: "FactCountSummary",
            showInColumn: "factCount",
            column: "factCount",
            valueFormat: "#,##0.#",
            displayFormat: "{0} шт",
            summaryType: "sum",
          },
          {
            name: "ResidueSummary",
            showInColumn: "residue",
            column: "residue",
            valueFormat: "#,##0.#",
            displayFormat: "{0} шт",
            summaryType: "sum",
          },

          {
            name: "LoadCapacitySummary",
            showInColumn: "planCount",
            column: "totalLoadCapacity",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },

          {
            name: "WeightSummary",
            showInColumn: "factCount",
            column: "totalWeight",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },

          {
            name: "ResidueWeightSummary",
            showInColumn: "residue",
            column: "residueWeight",
            valueFormat: "#,##0.#",
            displayFormat: "{0} кг",
            summaryType: "sum",
          },
        ],
      },
      firstRowExpanded: false,
      editingConfig: new TableEditingConfig({
        allowDeleteAll: false,
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: true,
        allowDataFill: false,
        allowExport: false,
        allowImport: false,
        allowClearFilter: false,
        allowDeleteMultipleRows: false,
        allowGrouping: false,
        allowColumnReordering: true,
        allowResetSelectedRows: true,
        allowChangeScrollSettings: true,
        mode: "row",
        chooseColumns: true,
      }),
      selectModeEditingConfig: new TableEditingConfig({
        allowUpdating: false,
        allowAdding: false,
        allowDeleting: false,
        confirmDelete: false,
        allowDataFill: false,
        allowExport: false,
        allowDeleteMultipleRows: false,
      }),
      columns: [
        new EnumColumnConfig({
          dataField: "transportTypeDescription",
          caption: "Тип",
          enumType: "transportType",
          modelField: "transportType",
          modelDescField: "transportTypeDescription",
          descriptionColumnName: "transportTypeDescription",
        }),
        new TextColumnConfig({
          dataField: "name",
          caption: "Наименование",
        }),
        new TextColumnConfig({
          dataField: "sizes",
          caption: "Размеры (мм)",
        }),
        new TextColumnConfig({
          dataField: "displacementDescription",
          caption: "Смещение",
        }),
        new TextColumnConfig({
          dataField: "gapDescription",
          caption: "Отступы (мм)",
        }),

        new DecimalColumnConfig({
          dataField: "loadCapacity",
          caption: "Грузоподъемность (кг)",
          hideFraction: true,
          decimalPoints: 0,
          min: 0,
          step: 1,
        }),

        new IntegerColumnConfig({
          dataField: "planCount",
          caption: "План (шт)",
          min: 0,
        }),
        new IntegerColumnConfig({
          dataField: "factCount",
          caption: "Загружено (шт)",
          min: 0,
        }),
        new IntegerColumnConfig({
          dataField: "residue",
          caption: "Остаток (шт)",
          min: 0,
        }),
      ],

      designConfig: new TableDesignConfig({
        popUpColumnsCount: 1,
        popUpWidth: "850px",
        horizontalScroll: false,
      }),

      selectionConfig: new TableSelectionConfig({
        mode: "multiple",
        selectAllMode: "allPages",
        showCheckBoxesMode: "none",
      }),
      pageSizes: [10, 20, 50, 100],
    };
  },
  created() {
    if (this.selectMode) {
      this.editingConfig = this.selectModeEditingConfig;
    }
    if (this.gridHeight) {
      this.designConfig.height = this.gridHeight;
    }

    this.setColums();
  },
});
